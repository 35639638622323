<template>
  <el-form ref="form" :model="form" label-width="80px">
    <el-form-item label="姓名">
      <el-input maxlength="20" v-model="form.name"></el-input>
    </el-form-item>
    <el-form-item label="手机号">
      <el-input v-model="form.phone"></el-input>
    </el-form-item>
    <el-form-item label="角色" v-if="!user.role || user.role ==='manger' || user.role ==='salesman'">
      <el-select v-model="form.role" placeholder="角色">
          <el-option label="业务经理" value="manger"></el-option>
          <el-option label="业务员" value="salesman"></el-option>
        </el-select>
    </el-form-item>
    <el-form-item label="所属上级" v-if="!user.role || user.role ==='manger' || user.role ==='salesman'">
      <el-select v-model="form.pid" placeholder="所属上级">
          <el-option
            v-for="user in users"
            :key="user.id"
            :label="user.name"
            :value="user.idStr"
            >
          </el-option>
        </el-select>
    </el-form-item>
    <el-form-item style="margin-top:60px">
      <el-button type="primary" @click="onSubmit" :loading="loading">{{user.id?'立即修改':'立即创建'}}</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
  export default {
    name: "createAccount",
    data() {
      return {
        loading:false,
        form: {
          name: '',
          phone: '',
          role: 'manger',
          pid: ''
        },
        root:[],
        manger:[],
        users:[]
      }
    },
    props: {
      user:{
        type:Object,
        default(){
          return {}
        }
      }
    },
    mounted(){
      this.userList()
      if(this.user.id){
        this.form = JSON.parse(JSON.stringify(this.user))
      }else{
        this.form = {
          name: '',
          phone: '',
          role: 'manger',
          pid: ''
        }
      }
    },
    watch: {
      "form.role": {
        handler(newVal, oldVal){
          this.setPidOption()
        },
        deep: true
      },
      user: {
        handler(newVal, oldVal){
          if(newVal.id){
            this.form = JSON.parse(JSON.stringify(newVal))
          }else{
            this.form = {
              name: '',
              phone: '',
              role: 'manger',
              pid: ''
            }
          }
        },
        deep: true
      }
    },
    methods: {
      userList() {
        var params = {}
        params.page = 0
        params.size = 100
        this.$api.post('/v1/user/list',params,res=>{
          if(res.errorCode === '0'){
            this.users = []
            this.root = []
            this.manger = []
            if(res.data && res.data.content && res.data.content.length > 0){
              //业务经理父级只能是管理员，业务员父级只能是业务经理
              res.data.content.map(v=>{
                if(v.role === 'root' && v.status === 'normal'){
                  this.root.push(v)
                }else if(v.role === 'manger' && v.status === 'normal'){
                  this.manger.push(v)
                }
                v.id = v.idStr
              })
              this.setPidOption()
            }
          }else{
            this.$message.error(res.msg)
          }
        },f=>{
          console.log(f);
          this.$message.error('操作失败！')
        })
      },
      setPidOption(){
        if(this.form.role === 'manger'){
          this.users = this.root
        }else if(this.form.role === 'salesman'){
          this.users = this.manger
        }
        if(!this.user.id){
          if(this.users.length > 0){
            this.form.pid = this.users[0].idStr
          }else{
            this.form.pid = ''
          }
        }
      },
      onSubmit() {
        // console.log(this.form);
        if(this.loading) return
        if(!this.form.name){
          this.$message.error('请填写用户姓名！')
          return
        }
        if(this.form.phone.length !== 11){
          this.$message.error('手机号码格式不正确！')
          return
        }
        if(!this.form.pid){
          this.$message.error('父级选择错误！')
          return
        }
        this.loading = true
        var params = this.form
        if(params.idStr) params.userId = params.idStr
        this.$api.post('/v1/user/add/user',params,res=>{
          this.loading = false
          if(res.errorCode === '0'){
            this.$message.success('创建成功!')
            this.$emit('reload');
          }else{
            this.$message.error(res.msg)
          }
        },f=>{
          this.loading = false
          console.log(f);
          this.$message.error(f.data.message)
        },'json')
      }
    }
  }
</script>

<style scoped>

</style>