<template>
  <el-form ref="form" :model="form" :rules="rules" label-width="100px">
    <el-form-item label="菜单名" prop="menuName">
      <el-input v-model="form.menuName"></el-input>
    </el-form-item>
    <el-form-item label="菜单标识码" prop="menuCode">
      <el-input v-model="form.menuCode"></el-input>
    </el-form-item>
    <el-form-item label="父级菜单" prop="pid">
      <el-select v-model="form.pid"  placeholder="选择父级">
          <el-option
            v-for="menu in menus"
            :key="menu.id"
            :label="menu.menuName"
            :value="menu.id"
            >
          </el-option>
        </el-select>
    </el-form-item>
    <el-form-item label="跳转方式" prop="jumpMethod">
      <el-radio-group v-model="form.jumpMethod">
        <el-radio label="站内跳转" value="站内跳转"></el-radio>
        <el-radio label="站外跳转" value="站外跳转"></el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="icon" prop="icon">
      <el-input v-model="form.icon"></el-input>
    </el-form-item>
    <el-form-item label="新页面" prop="newPage">
      <el-radio-group v-model="form.newPage">
        <el-radio label="是" value="是"></el-radio>
        <el-radio label="否" value="否"></el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="跳转路径" prop="jumpWay">
      <el-input v-model="form.jumpWay"></el-input>
    </el-form-item>
    <el-form-item label="排列序号" prop="serialNumber">
      <el-input type="Number" v-model="form.serialNumber"></el-input>
    </el-form-item>
    <el-form-item label="菜单状态" prop="status">
      <el-select v-model="form.status" placeholder="菜单状态">
          <el-option label="启用" value="启用"></el-option>
          <el-option label="禁用" value="禁用"></el-option>
        </el-select>
    </el-form-item>
    <el-form-item label="菜单功能">
      <el-table
        :data="tableData"
        size="mini"
        border
        style="width: 100%">
        <el-table-column
          prop="name"
          header-align="center"
          align="center"
          label="功能">
          <template slot-scope="scope">
            <input class="table-input" type="text" maxlength=20 placeholder="请填写功能名称" v-model="scope.row.name">
          </template>
        </el-table-column>
        <el-table-column
          prop="code"
          header-align="center"
          align="center"
          label="标识码">
          <template slot-scope="scope">
            <input class="table-input" type="text" maxlength=20 placeholder="请填写标识码" v-model="scope.row.code">
          </template>
        </el-table-column>
        <el-table-column
          prop="button"
          header-align="center"
          align="center"
          label="操作">
          <template slot-scope="scope">
            <el-link type="primary" @click="del(scope.$index)">删除</el-link>
          </template>
        </el-table-column>
      </el-table>
      <el-button style="margin-top:20px" size="small" type="primary" @click="add()">添加功能菜单</el-button>
    </el-form-item>
    <el-form-item style="margin-top:60px">
      <el-button type="primary" @click="onSubmit('form')" :loading="loading">立即{{form.id?'修改':'创建'}}</el-button>
      <el-button @click="cancel">取消</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
  export default {
    name: "createMenu",
    data() {
      return {
        loading:false,
        tableData:[
        ],
        menus:[{id:'0',menuName:'顶级菜单'}],
        form: {
          menuName: '',
          menuCode: '',
          pid: '0',
          jumpMethod: '站内跳转',
          jumpWay: '',
          serialNumber: 0,
          status: '启用',
          newPage:'否',
          icon:''
        },
        rules: {
          menuName: [
            { required: true, message: '请输入菜单名称', trigger: 'blur' },
            { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
          ],
          menuCode: [
            { required: true, message: '请输入菜单标识码', trigger: 'blur' },
            { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
          ],
          pid: [
            { required: true, message: '请选择父级菜单', trigger: 'blur' }
          ],
          jumpMethod: [
            { required: true, message: '请选择跳转方式', trigger: 'blur' }
          ],
          newPage: [
            { required: true, message: '请选择是否为新页面打开', trigger: 'blur' }
          ],
          jumpWay: [
            { required: true, message: '请输入跳转路径', trigger: 'blur' },
            { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
          ],
          status: [
            { required: true, message: '请选择菜单状态', trigger: 'blur' }
          ],
        }
      }
    },
    props: {
      menu:{
        type:Object,
        default(){
          return {}
        }
      },
      allMenus:{
        type:Array,
        default(){
          return []
        }
      }
    },
    watch: {
      menu: {
        handler(newVal, oldVal){
          if(newVal.id){
            this.form = JSON.parse(JSON.stringify(newVal))
            this.tableData = newVal.menuUsage.concat()
          }else{
            this.form = {
              menuName: '',
              menuCode: '',
              pid: '0',
              jumpMethod: '站内跳转',
              jumpWay: '',
              serialNumber: 0,
              status: '启用',
              newPage:'否',
              icon:''
            }
            this.tableData = []
          }
        },
        deep: true
      },
      allMenus: {
        handler(newVal, oldVal){
          let menus = [{id:'0',menuName:'顶级菜单'}]
          newVal.map(v=>{
            if(v.pid === '0'){
              menus.push(v)
            }
          })
          this.menus = menus
        },
        deep: true
      }
    },
    mounted(){
      if(this.menu.id){
        this.form = JSON.parse(JSON.stringify(this.menu))
        this.tableData = this.menu.menuUsage.concat()
      }else{
        this.form = {
          menuName: '',
          menuCode: '',
          pid: '0',
          jumpMethod: '站内跳转',
          jumpWay: '',
          serialNumber: 0,
          status: '启用',
          newPage:'否',
          icon:''
        }
        this.tableData = []
      }
      let menus = [{id:'0',menuName:'顶级菜单'}]
      this.allMenus.map(v=>{
        if(v.pid === '0'){
          menus.push(v)
        }
      })
      this.menus = menus
    },
    methods: {
      add(){
        this.tableData.push({name:'',code:''})
      },
      cancel(){
        this.$emit('reload');
      },
      del(index){
        this.$confirm('确定要删除该菜单功能吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.tableData.splice(index,1);
        }).catch(() => {
        });
      },
      onSubmit(formName) {
        if(this.loading) return
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loading = true
            var params = this.form
            let menuUsage = this.tableData.concat()
            params.menuUsage = []
            menuUsage.map(v=>{
              if(v.name && v.code){
                params.menuUsage.push(JSON.stringify(v))
              }
            })
            this.$api.post('/v1/menu/add',params,res=>{
              this.loading = false
              if(res.errorCode === '0'){
                this.$message.success('操作成功!')
                this.$emit('reload');
              }else{
                this.$message.error(r.msg)
              }
            },f=>{
              this.loading = false
              console.log(f);
              this.$message.error(f.data.message)
            },'json')
          } else {
            return false;
          }
        });
        // console.log(this.form);
      }
    }
  }
</script>

<style scoped>
  .el-input,.el-select{
    width: 300px;
  }
  .table-input{
    border: none;
    padding: 5px 10px;
  }
</style>